import React, { useState, useRef, useEffect } from "react"
import { Link as GatsbyLink, navigate } from 'gatsby'
import { 
  HStack,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useColorMode,Switch,IconButton,Box, Flex, Link, Icon, Text, Heading, Image, Spacer, useDisclosure, Button, Drawer,DrawerOverlay,DrawerContent,DrawerCloseButton,DrawerHeader,DrawerBody,Stack,FormLabel,Input,InputGroup,InputRightElement, DrawerFooter,Modal,ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,FormControl,ModalFooter, Center } from "@chakra-ui/react"
import { 
  HamburgerIcon,CloseIcon,AddIcon,RepeatClockIcon} from '@chakra-ui/icons'
import Favicon1 from "../images/favicon1.png"
import {
  CalendarIcon,
  BookOpenIcon,
  CurrencyYenIcon,
} from '@heroicons/react/outline'
import {signup,isAuthenticated,portal,logout,getemail,kakin,login} from '../utils/signuplogin.js';

{/* const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
}) */}
export const isBrowser = typeof window !== "undefined"


const Header = ({path,title}) => {
   
   const [loaded, setLoaded] = useState("none");
   const [_retAuthenticated,SetRetAuthenticated] = useState("");


   const [resentView,SetResentView] = useState("");
   const LoadingRef = useRef();
   useEffect(() =>{
      if(isBrowser){
         var recent_views_list = JSON.parse(localStorage.getItem('pachi_recent_views'));
         SetResentView(recent_views_list);
      }

      if(path){
         console.log(path);
         console.log(title);
         var recent_views = [{
            url: path,
            title: title,
         }];
         if(isBrowser){
            var flag = window.localStorage.getItem( "pachi_recent_views" );
            if(flag){
               if(path.indexOf("pdata_") != -1){
                     var recent_views_start = JSON.parse(window.localStorage.getItem('pachi_recent_views'));
                     if (recent_views_start) {
                        for(var i=0;i<5;i++) {
                           if (recent_views_start[i] && recent_views[0].url != recent_views_start[i].url) {
                                 recent_views.push(recent_views_start[i]);
                           }
                        }
                     }
                     window.localStorage.setItem('pachi_recent_views',JSON.stringify(recent_views));
               }
            }else{
               if(path.indexOf("pdata_") != -1){
                  window.localStorage.setItem('pachi_recent_views',JSON.stringify(recent_views));
               }
            }

            var recent_views_list = JSON.parse(localStorage.getItem('pachi_recent_views'));
            SetResentView(recent_views_list);
         }
      }
   },[LoadingRef])

   const NewRegistration = () => {
      const { isOpen, onOpen, onClose } = useDisclosure();
      const firstField = useRef();
      
      const [show, setShow] = useState(false);
      const handleClick = () => setShow(!show);

      const [state, setState] = useState({
         email: '',
         password: '',
      });

      const [responseText, setResponseText] = useState("");
      
      const onChange = (e) => {
         setState({...state,[e.target.name]:e.target.value});
         setResponseText("");
      };

      const _signup = async () => {
         let ret = await signup(state.email,state.password);
         if(ret.error){
         setResponseText(ret.error.text);
         return;
         }
         console.log(ret);
         window.sessionStorage.setItem('userData',JSON.stringify(ret.userData));
         window.sessionStorage.setItem('token',JSON.stringify(ret.token));
         if(ret.stripe_url){
         navigate(ret.stripe_url);
         return;
         }
      }

      return (
         <>
         <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
            新規登録
         </Button>
         <Drawer
            isOpen={isOpen}
            placement="right"
            initialFocusRef={firstField}
            onClose={onClose}
         >
            <DrawerOverlay />
            <DrawerContent>
               <DrawerCloseButton />
               <DrawerHeader borderBottomWidth="1px">
               新規登録
               </DrawerHeader>

               <DrawerBody>
               <Stack spacing="24px">
                  <Box>
                     <FormLabel htmlFor="mailaddress">メールアドレス</FormLabel>
                     <Input
                     ref={firstField}
                     name="email"
                     placeholder="example@gmail.com"
                     onChange={onChange}
                     />
                  </Box>

                  <Box>
                     <FormLabel htmlFor="password">パスワード</FormLabel>
                     <InputGroup size="md">
                     <Input
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        onChange={onChange}
                     />
                     <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                           {show ? "Hide" : "Show"}
                        </Button>
                     </InputRightElement>
                     </InputGroup>
                  </Box>

                  <Box>
                     <Text>
                     説明・注意
                     </Text>
                     <Text>
                     私も当時とこうその相違人というはずのためが疑わたた。同時に先刻を話めはとやかくその発達たですかもを聴こているたがも意味なれたでて、わざわざには合っなんうまし。
                     </Text>
                  </Box>
               </Stack>
               </DrawerBody>

               <DrawerFooter borderTopWidth="1px" display="box">
               <Text m={1} color="tomato">
                  {responseText}
               </Text>
               <Button variant="outline" mr={3} onClick={onClose}>
                  キャンセル
               </Button>
               <Button onClick={_signup} colorScheme="blue">
                  続行
               </Button>
               </DrawerFooter>
            </DrawerContent>
         </Drawer>
         </>
      )
   }

   const UserLogined = () => {
      const { isOpen, onOpen, onClose } = useDisclosure();
      const firstField = useRef();

      const _logout = () => {
         logout();
         onClose();
         
         SetRetAuthenticated(RetAuthenticated);
         return;
      }

      return (
         <>
         <Button colorScheme="teal" onClick={onOpen}>
         ユーザー情報
         </Button>
         <Drawer
            isOpen={isOpen}
            placement="right"
            initialFocusRef={firstField}
            onClose={onClose}
         >
            <DrawerOverlay />
            <DrawerContent>
               <DrawerCloseButton />
               <DrawerHeader borderBottomWidth="1px">
               ユーザー情報
               </DrawerHeader>

               <DrawerBody>
               <Stack spacing="24px">
                  <Box>
                     <FormLabel htmlFor="mailaddress">メールアドレス</FormLabel>
                     <Text>{getemail()}</Text>
                  </Box>

                  {
                  isAuthenticated() === "notpaid" ? 
                  <Button onClick={kakin} colorScheme="blue">
                     課金
                  </Button>
                  :
                  <Button onClick={portal} colorScheme="blue">
                     課金情報
                  </Button>
                  }

                  <Box>
                     <Text>
                     説明・注意
                     </Text>
                     <Text>
                     私も当時とこうその相違人というはずのためが疑わたた。同時に先刻を話めはとやかくその発達たですかもを聴こているたがも意味なれたでて、わざわざには合っなんうまし。
                     </Text>
                  </Box>
               </Stack>
               </DrawerBody>

               <DrawerFooter borderTopWidth="1px" display="box">
               <Button variant="outline" mr={3} onClick={onClose}>
                  キャンセル
               </Button>
               <Button onClick={_logout} colorScheme="blue">
                  ログアウト
               </Button>
               </DrawerFooter>
            </DrawerContent>
         </Drawer>
         </>
      )
   }

   const Login = () => {
      const { isOpen, onOpen, onClose } = useDisclosure()
      const initialRef = useRef()

      const [show, setShow] = useState(false);
      const handleClick = () => setShow(!show);

      const [state, setState] = useState({
         email: '',
         password: '',
      });

      const [responseText, setResponseText] = useState("");
      
      const onChange = (e) => {
         setState({...state,[e.target.name]:e.target.value});
         setResponseText("");
      };

      const _login = async () => {
         let ret = await login(state.email,state.password);
         if(ret.error){
         setResponseText(ret.error.text);
         return;
         }
         console.log(ret);
         window.sessionStorage.setItem('userData',JSON.stringify(ret.userData));
         window.sessionStorage.setItem('token',JSON.stringify(ret.token));
         onClose();
         
         SetRetAuthenticated(RetAuthenticated);
         return;
      }

      return (
         <>
         <Button onClick={onOpen}>ログイン</Button>

         <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={onClose}
         >
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>ログイン</ModalHeader>
               <ModalCloseButton />
               <ModalBody pb={6}>
               <FormControl>
                  <FormLabel>メールアドレス</FormLabel>
                  <Input 
                     ref={initialRef} 
                     name="email"
                     placeholder="example@gmail.com"
                     onChange={onChange}
                  />
               </FormControl>

               <FormControl mt={4}>
                  <FormLabel htmlFor="password">パスワード</FormLabel>
                  <InputGroup size="md">
                     <Input
                     pr="4.5rem"
                     type={show ? "text" : "password"}
                     placeholder="Enter password"
                     name="password"
                     onChange={onChange}
                     />
                     <InputRightElement width="4.5rem">
                     <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? "Hide" : "Show"}
                     </Button>
                     </InputRightElement>
                  </InputGroup>
               </FormControl>
               </ModalBody>

               <ModalFooter>
               <Text m={1} color="tomato">
                  {responseText}
               </Text>
               <Button  onClick={_login} colorScheme="blue" mr={3}>
                  ログイン
               </Button>
               <Button onClick={onClose}>キャンセル</Button>
               </ModalFooter>
            </ModalContent>
         </Modal>
         </>
      )
   }

   const RetAuthenticated = () =>{
      if(isBrowser){
         let isAuth = isAuthenticated();
         if(isAuth === "true"){
         return (
            <>
            <Box m={2}>
               <UserLogined m={2}/>
            </Box>
            </>
         )
         }else if(isAuth === "notpaid"){
         return (
            <>
            <Box m={2}>
               <UserLogined m={2}/>
            </Box>
            </>
         )
         }else{
         return (
            <>
            <Box m={2}>
               <NewRegistration m={2}/>
            </Box>
            <Box m={2}>
               <Login m={2}/>
            </Box>
            </>
         )
         }
      }else{
         return (<></>)
      }
   };


   const MobileDrawer = ({placement}) => {
      const { isOpen, onOpen, onClose } = useDisclosure();
      const firstField = useRef();
      
      return (
      <>
         <Box p={3}>
            <IconButton
            aria-label="Open Menu"
            icon={
               <HamburgerIcon w={"7vw"} h={"7vw"} />
            } 
            w={"9vw"} h={"9vw"}
            minW={"0"}
            // pos="absolute" top="0" right="0" 
            onClick={onOpen}
            // onClick={() => changeDisplay('flex')}
            // display={['flex', 'flex', 'none', 'none']}
            />
         </Box>
         {/* <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
            Create user
         </Button> */}
         <Drawer
            isOpen={isOpen}
            placement={placement}
            initialFocusRef={firstField}
            onClose={onClose}
         >
            <DrawerOverlay />
            <DrawerContent>
               <DrawerCloseButton />
               <DrawerHeader borderBottomWidth="1px">
                  メニュー
               </DrawerHeader>

               <DrawerBody p={0}>
                  <Box fontWeight="bold">
                     <Link as={GatsbyLink} to="/psearch">
                        <Box borderBottom="1px" borderColor="gray.200" p={3}>
                           <HStack>
                              <Center w={"15%"}>
                                 <Icon as={BookOpenIcon}/>
                              </Center>
                              <Box>
                                 <Box marginLeft={"2rem"}>機種検索</Box>
                              </Box>
                           </HStack>
                        </Box>
                     </Link>
                     <Link as={GatsbyLink} to="/events">
                        <Box borderBottom="1px" borderColor="gray.200" p={3}>
                           <HStack>
                              <Center w={"15%"}>
                                 <Icon as={CalendarIcon}/>
                              </Center>
                              <Box>
                                 <Box marginLeft={"2rem"}>イベント情報</Box>
                              </Box>
                           </HStack>
                        </Box>
                     </Link>
                     <Link as={GatsbyLink} to="/category/diary/1">
                        <Box borderBottom="1px" borderColor="gray.200" p={3}>
                           <HStack>
                              <Center w={"15%"}>
                                 <Icon as={CurrencyYenIcon}/>
                              </Center>
                              <Box>
                                 <Box marginLeft={"2rem"}>実践記</Box>
                              </Box>
                           </HStack>
                        </Box>
                     </Link>
                     <Box borderBottom="1px" borderColor="gray.200" p={3}>
                        <HStack>
                           <Center w={"15%"}>
                              <Icon as={RepeatClockIcon}/>
                           </Center>
                           <Box>
                              <Box marginLeft={"2rem"}>履歴</Box>
                           </Box>
                        </HStack>
                        <Box fontWeight="normal" pl={3} fontSize="smaller">
                           {resentView && resentView.map( val => {
                              return (
                                 <>
                                 <Box p={2}>
                                    <Link as={GatsbyLink} to={val.url}>
                                       {val.title}
                                    </Link>
                                 </Box>
                                 </>
                              )
                           })}
                        </Box>
                     </Box>
                  </Box>
                  <RetAuthenticated />
               </DrawerBody>

               <DrawerFooter borderTopWidth="1px">
                  <Button variant="outline" mr={3} onClick={onClose}>
                     閉じる
                  </Button>
               </DrawerFooter>
            </DrawerContent>
         </Drawer>
      </>
      )
   }

  return (
    <>
    {/* デスクトップ */}
    <Flex  display={{ base: "none", lg: "flex" }} pos="fixed" bg="white" w="100%" p={4} color="" zIndex={1}>
      <Flex>
        <Link as={GatsbyLink} to="/">
          <Flex>
            <Image 
               display={loaded} 
               onLoad={(e)=>{setLoaded("block");}}
               m={2} w={9} h={"100%"} src={Favicon1} alt="パチンカーズネット" />
            <Heading as="h1" size="2xl" fontFamily="MyFont">
              パチンカーズネット
            </Heading>
          </Flex>
        </Link>
      </Flex>
      

      <Spacer />

      <Flex fontWeight="bold">
        <Box m={2} mt={3} textAlign="center">
          <Link as={GatsbyLink} to="/psearch">
            <Icon as={BookOpenIcon} w={6} m={2} mb={3}/>
            機種検索
          </Link>
        </Box>
        <Box m={2} mt={3}>
          <Link as={GatsbyLink} to="/events">
            <Icon as={CalendarIcon}  w={6} m={2} mb={3}/>
            イベント情報
          </Link>
        </Box>
        <Box m={2} mt={3} mr={3}>
          <Link as={GatsbyLink} to="/category/diary/1">
            <Icon as={CurrencyYenIcon}  w={6} m={2} mb={3}/>
            実践記
          </Link>
        </Box>

        <RetAuthenticated />
        
      </Flex>
    </Flex>
    
    {/* モバイル */}
   <Box display={{ base: "box", lg: "none" }}>
      <HStack bg="white" w="100%" h="11vw" minH="15px">
         
         <Center w={"100%"}>
            <Link as={GatsbyLink} to="/" display="block">
               <HStack>
                  <Image 
                     display={loaded} 
                     onLoad={(e)=>{setLoaded("block");}}
                     w={"4vw"} h={"100%"} src={Favicon1} alt="パチンカーズネット" />
                  <Text my={2} as="h1" fontSize={"5vw"} fontFamily="MyFont" fontWeight="bold" isTruncated>
                     パチンカーズネット
                  </Text>
               </HStack>
            </Link>
         </Center>

         <MobileDrawer placement="right" />
         {/* <Box>
            <IconButton
            aria-label="Open Menu"
            icon={
               <HamburgerIcon w={"7vw"} h={"7vw"} />
            } 
            w={"9vw"} h={"9vw"}
            minW={"0"}
            pos="absolute" top="0" right="0" 
            // onClick={() => changeDisplay('flex')}
            // display={['flex', 'flex', 'none', 'none']}
            />
         </Box> */}
      </HStack>

      <HStack  borderTop="1px" pos="fixed" bottom="0" bg="white" w="100%" h="11vw" zIndex={1} minH="15px">
         <MobileDrawer  placement="left"/>
         <Center w={"65%"}>
            <Link as={GatsbyLink} to="/" display="block">
               <HStack>
                  <Image 
                     display={loaded} 
                     onLoad={(e)=>{setLoaded("block");}}
                     w={"4vw"} h={"100%"} src={Favicon1} alt="パチンカーズネット"
                  />
                  <Text my={2} as="h1" fontSize={"5vw"} fontFamily="MyFont" fontWeight="bold" isTruncated>
                     パチンカーズネット
                  </Text>
               </HStack>
            </Link>
         </Center>
        
        {/* <Box>
          <IconButton
            aria-label="Open Menu"
            icon={
              <HamburgerIcon w={"7vw"} h={"7vw"} />
            } 
            w={"9vw"} h={"9vw"}
            minW={"0"}
            pos="absolute" top="0" right="0" 
            // onClick={() => changeDisplay('flex')}
            // display={['flex', 'flex', 'none', 'none']}
          />
        </Box> */}
      </HStack>

      {/* <Spacer /> */}

      {/* <Box fontWeight="bold"> */}

        {/* <Box m={2} mt={3}>
          <Link as={GatsbyLink} to="/psearch">
            <Icon as={BookOpenIcon} w={6} m={2} mb={3}/>
            機種検索
          </Link>
        </Box>
        <Box m={2} mt={3}>
          <Link as={GatsbyLink} to="/events">
            <Icon as={CalendarIcon}  w={6} m={2} mb={3}/>
            イベント情報
          </Link>
        </Box>
        <Box m={2} mt={3} mr={3}>
          <Link as={GatsbyLink} to="/category/diary/1">
            <Icon as={CurrencyYenIcon}  w={6} m={2} mb={3}/>
            実践記
          </Link>
        </Box> */}

        {/* <RetAuthenticated /> */}
        {/* {_retAuthenticated} */}
      {/* </Box> */}

    </Box>

    
    </>
  )
}

export default Header;